const $ = jQuery;
const loadMoreBtn = document.querySelector('.js-load-more .c-button');
const postsContainer = document.querySelector('.js-resources-container');
let data = {};

export default function resourceArchive() {
	loadMore();
}

function loadMore() {
	if (loadMoreBtn) {
		loadMoreBtn.addEventListener('click', () => {
			let postIds = getCurrentPostIds();
			getPosts(postIds);
		});
	}
}

function getCurrentPostIds() {
	let posts = postsContainer.querySelectorAll('.card-resource');

	let ids = [];
	posts.forEach(function (post) {
		let id = post.dataset.postId;
		if (id) {
			ids.push(id);
		}
	});

	return ids;
}

/**
 * Get posts
 */
function getPosts(postIds = false) {
	if (postIds && postIds.length > 0) {
		data.posts = postIds;

		$.ajax({
			url       : menaObject.ajaxurl,
			type      : 'GET',
			data      : {
				'action': 'getResources',
				'data'  : data,
			},
			beforeSend: function () {
				postsContainer.classList.add('loading');
			},
			success   : function (response) {
				if (!response) return;

				if (response.success && response.data.posts.length > 0) {
					let posts = response.data.posts;
					let postsHtml = '';

					posts.forEach(post => {
						postsHtml += post;
					});

					if (response.data.loadMoreBtn === 'hide') {
						$('.js-load-more').hide();
					}

					if (response.data.loadMoreBtn === 'show') {
						$('.js-load-more').show();
					}

					if (response.data.loadMore) {
						$('.js-resources-container').append(postsHtml);
					} else {
						postsContainer.innerHTML = postsHtml;
					}
				} else {
					if (!response.data.loadMore) {
						postsContainer.innerHTML = `<div>${response.data.empty}</div>`;
					}
				}

				if (data.posts) {
					delete data.posts;
				}
				postsContainer.classList.remove('loading');
			},
			error     : function (error) {
				console.log(error);
			},
		});
	}
}
