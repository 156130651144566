export default function MobileMenu() {
	const mobileMenuToggles = document.querySelectorAll('.js-menu-toggle');
	const mobileButton = document.querySelector('.resp-menu .c-button');

	if (!mobileMenuToggles  ) {
		return;
	}

	mobileButton.addEventListener('click', () =>{
			const body = document.querySelector('body');
			body.classList.remove('menu-open');
	})

	mobileMenuToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			const body = document.querySelector('body');
			//toggle.classList.toggle('menu-toggle--active');
			body.classList.toggle('menu-open');
		});
	});
}