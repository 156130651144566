import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(SplitText, ScrollTrigger);

export default function animations() {
	/*
	const animationTexts = document.querySelectorAll('.hero__content h1, .text-with-image__content h2, .overview__content h2, .cards-with-lottie__content h2, .cards__content h2, subscribe__content h2, .info-cards__content h2');
	*/

	const animationTexts = document.querySelectorAll('.hero__content h1, .news-archive h1, .events-archive h1, .content:first-child h1, .resource-archive h1,.text-with-image__content h2, .overview__content h2, .cards-with-lottie h2, .info-cards h2, .logos h2, .banner h2, .subscribe h2, .latest-news h2, .cards h2');

	animationTexts.forEach((text) => {
		// Add width: fit-content
		text.style.width = 'fit-content';
		// Margin left and right auto
		// If text has style text-align: center then add margin 0 auto
		if (text.style.textAlign === 'center') {
			text.style.marginLeft = 'auto';
			text.style.marginRight = 'auto';
		}
		// Add margin 0 auto to center the text
		const splitText = new SplitText(text, {
			type: 'lines',
			linesClass: 'title-line',
			tag: 'span',
			reduceWhiteSpace: true,
		});
		splitText.lines.forEach((line, index) => {
			const div = document.createElement('div');
			div.classList.add('line-wrapper');
			div.style = 'position: absolute; overflow: hidden; width: 100%; height: 100%; display: flex; align-items: center;';
			line.appendChild(div);


			const divInner = document.createElement('div');
			divInner.classList.add('line-inner');
			divInner.style = 'width: 0%; height: 100%; background-color: var(--color-primary); position: absolute; left: 0; top: 0; zIndex: 1;';
			div.appendChild(divInner);

			ScrollTrigger.create({
				trigger: text,
				start: 'top 80%',
				end: 'bottom center',
				onEnter: () => {
					const tl = gsap.timeline({
						delay: index * 0.05, // Applying delay here
					});

					tl.to(divInner, {
						width: '100%',
						duration: 0.6,
						ease: 'power1.out',
					}).to(divInner, {
						xPercent: 101,
						duration: 0.6,
						ease: 'power1.out',
					});
				},
			});
		});
	});
}
