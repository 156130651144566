const $ = jQuery;

const eventData = {
	category: null,
	sorting : null,
};

const eventsContainer = document.querySelector('.js-events-archive-container');
const filterButtonCategoriesEvents = document.querySelector('.events-archive__button-dropdown .c-button--filter');
const dropdownMenuCategoriesEvents = document.querySelector('.js-events-archive-categories');
const filterButtonSortingEvents = document.querySelector('.events-archive__date-dropdown .c-button--filter');
const dropdownMenuSortingEvents = document.querySelector('.js-events-archive-sorting');
const loadMoreBtnEvents = document.querySelector('.js-load-more .c-button');

export default function eventsArchive() {
	getCategoriesEvents();
	getSortingEvents();
	toggleDropdownMenuEvents(filterButtonCategoriesEvents, dropdownMenuCategoriesEvents);
	toggleDropdownMenuEvents(filterButtonSortingEvents, dropdownMenuSortingEvents);
	restartDataEvents();
	loadMoreEvents();
}

function getCategoriesEvents() {
	const categories = document.querySelectorAll('.js-events-archive-categories li');

	categories.forEach(category => {
		category.addEventListener('click', function () {
			const termId = category.getAttribute('data-term-id');
			const categoryText = category.textContent;
			categories.forEach(cat => cat.classList.remove('active'));
			category.classList.add('active');
			dropdownMenuCategoriesEvents.style.display = 'none';
			eventData.category = termId;
			updateCategoriesButtonTextEvents(categoryText);
			getPostsEvents();
		});
	});
}

function loadMoreEvents() {
	if (loadMoreBtnEvents) {
		loadMoreBtnEvents.addEventListener('click', () => {
			let postIds = getCurrentPostIdsEvents();
			getPostsEvents(postIds);
		});
	}
}

function getCurrentPostIdsEvents() {
	let posts = eventsContainer.querySelectorAll('.card-post');
	let ids = [];
	posts.forEach(function (post) {
		let id = post.dataset.postId;
		if (id) {
			ids.push(id);
		}
	});

	return ids;
}

function getSortingEvents() {
	const sortingOptions = document.querySelectorAll('.js-events-archive-sorting li');

	sortingOptions.forEach(option => {
		option.addEventListener('click', function () {
			sortingOptions.forEach(opt => opt.classList.remove('active'));
			option.classList.add('active');
			dropdownMenuSortingEvents.style.display = 'none';
			eventData.sorting = option.getAttribute('data-sorting');
			updateSortingButtonTextEvents(option.textContent);
			getPostsEvents();
		});
	});
}

function updateCategoriesButtonTextEvents(text) {
	filterButtonCategoriesEvents.querySelector('span').textContent = text;
}

function updateSortingButtonTextEvents(text) {
	filterButtonSortingEvents.querySelector('span').textContent = text;
}

function toggleDropdownMenuEvents(button, menu) {
	if (button) {
		button.addEventListener('click', function (event) {
			event.preventDefault();
			menu.style.display = menu.style.display === 'block' ? 'none' : 'block';
		});

		document.addEventListener('click', function (e) {
			if (!button.contains(e.target) && !menu.contains(e.target)) {
				menu.style.display = 'none';
			}
		});
	}
}

function restartDataEvents() {
	const resetDataTrigger = document.querySelector('.js-reset-filters');
	const categories = document.querySelectorAll('.js-events-archive-categories li');

	if (resetDataTrigger) {
		resetDataTrigger.addEventListener('click', function () {
			eventData.category = null;
			eventData.sorting = null;

			categories.forEach(cat => cat.classList.remove('active'));
			categories.forEach(opt => opt.classList.remove('active'));

			updateCategoriesButtonTextEvents('By categories');
			updateSortingButtonTextEvents('By date');

			const firstCategory = document.querySelector('.js-events-archive-categories li:first-child');
			const firstSorting = document.querySelector('.js-events-archive-sorting li:first-child');

			if (firstCategory) {
				firstCategory.classList.add('active');
				eventData.category = firstCategory.getAttribute('data-term-id');
			}

			if (firstSorting) {
				firstSorting.classList.add('active');
				eventData.sorting = firstSorting.getAttribute('data-sorting');
			}

			getPostsEvents();
		});
	}
}

/**
 * Get posts
 */
function getPostsEvents(postIds = false) {
	if (postIds) {
		eventData.posts = postIds;
	}

	$.ajax({
		url       : menaObject.ajaxurl,
		type      : 'GET',
		data      : {
			'action': 'getAjaxEvents',
			'data'  : eventData,
		},
		beforeSend: function () {
			eventsContainer.classList.add('loading');
		},
		success   : function (response) {
			if (!response) return;

			if (response.success && response.data.posts.length > 0) {
				let posts = response.data.posts;
				let postsHtml = '';

				posts.forEach(post => {
					postsHtml += post;
				});

				if (response.data.loadMoreBtn === 'hide') {
					$('.js-load-more').hide();
				}

				if (response.data.loadMoreBtn === 'show') {
					$('.js-load-more').show();
				}

				if (response.data.loadMore) {
					$('.js-events-archive-container').append(postsHtml);
				} else {
					eventsContainer.innerHTML = postsHtml;
				}
			} else {
				if (!response.data.loadMore) {
					eventsContainer.innerHTML = `<div>${response.data.empty}</div>`;
					restartDataEvents();
				}
			}

			if (eventData.posts) {
				delete eventData.posts;
			}
			eventsContainer.classList.remove('loading');
		},
		error     : function (error) {
			console.log(error);
		},
	});
}
