export default function Footer() {

	const $ = jQuery;

	// If screen is less than 640 add event listener to all footer__menu h5 elements
	if (window.innerWidth < 640) {
		$('.footer__menu h5').on('click', function() {
			$(this).toggleClass('active');
			$(this).next().slideToggle();
		});
	}
}