
export default function () {
    const $ = jQuery;
    $('.js-copy-url-to-clipboard').on('click', function (event) {
        if (!$('.social-share-list__icon--clipboard').hasClass('active')) {
            $('.social-share-list__icon--clipboard').addClass('active');

            setTimeout(function () {
                $('.social-share-list__icon--clipboard').removeClass('active');
            }, 5000);
        }
        event.preventDefault();
        copy_url();
    });

    function copy_url() {
        var dummy = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }
}