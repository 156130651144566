export default function textWithImage() {   

    if (document.querySelector('.js-text-with-image--video')) {
		videoTrigger();
	}
    
}

function videoTrigger() {
    const videoContainers = document.querySelectorAll('.js-text-with-image--video');

    videoContainers.forEach(function(videoContainer) {
        let videoTrigger = videoContainer.querySelector('div');
        let video = videoContainer.querySelector('video');

        videoTrigger.addEventListener('click', function() {
            video.setAttribute('controls', '');    
            video.play();
            videoTrigger.remove();
        })
    });
}