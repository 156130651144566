const $ = jQuery;

const data = {
	category: null,
	sorting : null,
	s       : '',
};

const postsContainer = document.querySelector('.js-news-archive-container');
const filterButtonCategories = document.querySelector('.news-archive__button-dropdown .c-button--filter');
const dropdownMenuCategories = document.querySelector('.js-news-archive-categories');
const filterButtonSorting = document.querySelector('.news-archive__date-dropdown .c-button--filter');
const dropdownMenuSorting = document.querySelector('.js-news-archive-sorting');
const searchInput = document.querySelector('.js-news-archive-search input');
const loadMoreBtn = document.querySelector('.js-load-more .c-button');

export default function newsArchive() {
	getCategories();
	getSearch();
	getSorting();
	toggleDropdownMenu(filterButtonCategories, dropdownMenuCategories);
	toggleDropdownMenu(filterButtonSorting, dropdownMenuSorting);
	restartData();
	loadMore();
}

function getCategories() {
	const categories = document.querySelectorAll('.js-news-archive-categories li');

	categories.forEach(category => {
		category.addEventListener('click', function () {
			const termId = category.getAttribute('data-term-id');
			const categoryText = category.textContent;
			categories.forEach(cat => cat.classList.remove('active'));
			category.classList.add('active');
			dropdownMenuCategories.style.display = 'none';
			data.category = termId;
			updateCategoriesButtonText(categoryText);
			getPosts();
		});
	});
}

function loadMore() {
	if (loadMoreBtn) {
		loadMoreBtn.addEventListener('click', () => {
			let postIds = getCurrentPostIds();
			getPosts(postIds);
		});
	}
}

function getCurrentPostIds() {
	let posts = postsContainer.querySelectorAll('.card-post');
	let ids = [];
	posts.forEach(function (post) {
		let id = post.dataset.postId;
		if (id) {
			ids.push(id);
		}
	});

	return ids;
}

function getSorting() {
	const sortingOptions = document.querySelectorAll('.js-news-archive-sorting li');

	sortingOptions.forEach(option => {
		option.addEventListener('click', function () {
			sortingOptions.forEach(opt => opt.classList.remove('active'));
			option.classList.add('active');
			dropdownMenuSorting.style.display = 'none';
			data.sorting = option.getAttribute('data-sorting');
			updateSortingButtonText(option.textContent);
			getPosts();
		});
	});
}

function updateCategoriesButtonText(text) {
	filterButtonCategories.querySelector('span').textContent = text;
}

function updateSortingButtonText(text) {
	filterButtonSorting.querySelector('span').textContent = text;
}

function getSearch() {
	let timer;

	if (searchInput) {
		searchInput.addEventListener('keyup', function () {
			const value = searchInput.value;
			clearTimeout(timer);

			if (value.length > 2 || value.length === 0) {
				timer = setTimeout(function () {
					data.s = value;
					getPosts();
				}, 500);
			}
		});
	}
}

function toggleDropdownMenu(button, menu) {
	if (button) {

		button.addEventListener('click', function (event) {
			event.preventDefault();
			menu.style.display = menu.style.display === 'block' ? 'none' : 'block';
		});

		document.addEventListener('click', function (e) {
			if (!button.contains(e.target) && !menu.contains(e.target)) {
				menu.style.display = 'none';
			}
		});
	}
}

function restartData() {
	const resetDataTrigger = document.querySelector('.js-reset-filters');
	const categories = document.querySelectorAll('.js-news-archive-categories li');

	if (resetDataTrigger) {
		resetDataTrigger.addEventListener('click', function () {
			data.category = null;
			data.sorting = null;
			data.s = '';

			searchInput.value = '';

			categories.forEach(cat => cat.classList.remove('active'));
			categories.forEach(opt => opt.classList.remove('active'));

			updateCategoriesButtonText('By categories');
			updateSortingButtonText('By date');

			const firstCategory = document.querySelector('.js-news-archive-categories li:first-child');
			const firstSorting = document.querySelector('.js-news-archive-sorting li:first-child');

			if (firstCategory) {
				firstCategory.classList.add('active');
				data.category = firstCategory.getAttribute('data-term-id');
			}

			if (firstSorting) {
				firstSorting.classList.add('active');
				data.sorting = firstSorting.getAttribute('data-sorting');
			}

			getPosts();
		});
	}
}

/**
 * Get posts
 */
function getPosts(postIds = false) {
	if (postIds) {
		data.posts = postIds;
	}

	$.ajax({
		url       : menaObject.ajaxurl,
		type      : 'GET',
		data      : {
			'action': 'getAjaxPosts',
			'data'  : data,
		},
		beforeSend: function () {
			postsContainer.classList.add('loading');
		},
		success   : function (response) {
			if (!response) return;

			if (response.success && response.data.posts.length > 0) {
				let posts = response.data.posts;
				let postsHtml = '';

				posts.forEach(post => {
					postsHtml += post;
				});

				if (response.data.loadMoreBtn === 'hide') {
					$('.js-load-more').hide();
				}

				if (response.data.loadMoreBtn === 'show') {
					$('.js-load-more').show();
				}

				if (response.data.loadMore) {
					$('.js-news-archive-container').append(postsHtml);
				} else {
					postsContainer.innerHTML = postsHtml;
				}
			} else {
				if (!response.data.loadMore) {
					postsContainer.innerHTML = `<div>${response.data.empty}</div>`;
					restartData();
				}
			}

			if (data.posts) {
				delete data.posts;
			}
			postsContainer.classList.remove('loading');
		},
		error     : function (error) {
			console.log(error);
		},
	});
}
