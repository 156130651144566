/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import Footer from './components/footer';
import animations from './global/animations';
import copyToClipboard from './components/copyToClipboard';
import newsArchive from './blocks/newsArchive';
import resourceArchive from './blocks/resourceArchive';
import eventsArchive from './blocks/eventsArchive';
import textWithImage from './blocks/textWithImage';

document.addEventListener('DOMContentLoaded', () => {
	svg4everybody();
	MobileMenu();
	Footer();
	animations();

	if (document.querySelector('.news-archive')) {
		newsArchive();
	}

	if (document.querySelector('.resource-archive')) {
		resourceArchive();
	}

	if (document.querySelector('.text-with-image')) {
		textWithImage();
	}

	if (document.querySelector('.events-archive')) {
		eventsArchive();
	}

	if (document.querySelector('.js-copy-url-to-clipboard')) {
		copyToClipboard();
	}
});
